import * as t from 'io-ts';
import { decode } from '../common/mappingUtils/iotsDecoder';

const tableColumn = t.union([
    t.literal('details'),
    t.literal('timestamp'),
    t.literal('driverName'),
    t.literal('mileage'),
    t.literal('speed'),
    t.literal('fuelLevel'),
    t.literal('address'),
    t.literal('distanceToPrevious'),
    t.literal('durationSincePrevious'),
    t.literal('stateOfCharge'),
]);

const timeRangeType = t.union([
    t.literal('TODAY'),
    t.literal('YESTERDAY'),
    t.literal('LAST_24_HOURS'),
    t.literal('LAST_3_DAYS'),
    t.literal('LAST_10_DAYS'),
    t.literal('CUSTOM'),
]);

export const includeEventCodec = t.union([
    t.literal('border-crossing'),
    t.literal('driver-card'),
    t.literal('driving-state'),
    t.literal('geofence'),
    t.literal('ignition-state'),
    t.literal('position'),
    t.literal('pto-state'),
    t.literal('first-and-last-position'),
]);

const tableViewModeType = t.union([t.literal('SINGLE_CARD'), t.literal('MULTI_CARDS'), t.literal('TABLE')]);

const persistenceCodec = t.partial({
    selectedAssetIds: t.array(t.string),
    selectedGroupIds: t.array(t.string),
    fromTimestamp: t.string,
    toTimestamp: t.string,
    timeRangeType,
    includeEvents: t.array(includeEventCodec),
    hiddenTableColumns: t.array(tableColumn),
    sortDir: t.union([t.literal('asc'), t.literal('desc')]),
    sortByColumn: tableColumn,
    search: t.string,
    treeFilter: t.string,
    tableViewMode: tableViewModeType,
});

export type BrowserPersistenceState = t.TypeOf<typeof persistenceCodec>;

export const decodeBrowserPersistence = (parsedObject: unknown): BrowserPersistenceState =>
    decode(parsedObject, persistenceCodec);
